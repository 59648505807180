var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

var subsiteHijackMainNav = ['trapwatch'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var sidebarCards = true; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

$('.adminBar, .adminBarEdit').insertBefore('#pageHeader')

$('.pageHeader').css('top', $('.adminBarEdit').css('height'));

$('.headerContent > .mainCallToAction').insertAfter('#menuMain');

$('<li class="level1 search"><div class="searchWrapper"><a class="searchButton">Search</a></div></li>').prependTo('ul.topLevel');

if ($('.carouselDetailWrapper').length) {
  $('.carouselSlide').each(function () {
    $('.carousel > .carouselButtons').clone().appendTo($(this).find('.carouselSlideDetail'));
  })
  $('.carousel > .carouselButtons').remove();
}

if ($('body').hasClass('homepage')) {
  $('document').ready(function () {
    $('.homefeaturecategory-homeboximpactstats').insertAfter('.homeBox1');
    $('.stats-inner').appendTo('.homefeaturecategory-homeboximpactstats');
    $('.homeFeedBox1').insertAfter('.homefeaturecategory-homeboximpactstats');
    $('.homefeaturecategory-homeboxquickgiving').insertAfter('.homeFeedBox1');
    $('.homefeaturecategory-homeboxquickgiving .quickGivingPanel .donateBtn').text('Donate now >');
    $('.homepage-quickgiving--copy, .homepage-quickgiving--container > span').wrapAll('<div class="homepage-quickgiving--inner"></div>');
    $('.feedItemDetailsWrapper').each(function () {
      if ($(this).find('span.tag').length) {
        $(this).find('span.tag').insertBefore($(this).find('.feedTitle'));
      }
    })
    $(".homeFeed").each(function () {
      if (!$('.feedsTitle + *').hasClass('.feedList')) {
        var $thisIntro = $(this).find(".feedsTitle ~ *:not(.feedList)");
        $thisIntro.wrapAll('<div class="feedTitleSnippet"></div>');
        $(this).find(".feedsTitle").prependTo($(this).find('.feedTitleSnippet'));
        $(".feedTitleSnippet > *:not(.feedsTitle)").clone().insertAfter($(this).find('.feedList')).wrapAll('<div class="feedSnippetAfterList"></div>');
      }
    });
  })
}

$('document').ready(function () {
  $('.footerBox.NewsletterSign-up > *').wrapAll('<div class="newsletter-inner"></div>');
  $('.footerBox6').insertAfter('.pageFooter');

  if ($('body').hasClass('listing-with-feature')) {
    $('.title, .breadcrumbWrapper').wrapAll('<div class="listingHeading"><div class="listingHeadingInner"</div></div>');
    $('.listingHeading').insertBefore(".carousel");
    $(window).on("load resize scroll", function (e) {
      $('.listingHeading').css('padding-top', $('.pageHeader').css('height'));
    });
  }

  if ($('body[class*="PostBody"]').length) {
    $('.carouselSlide img').each(function () {
      $(this).attr('srcset', '');
      $(this).attr('src', $(this).attr('src').replace('width=1920&height=620', 'width=1036&height=500'));
      $(this).attr('src', $(this).attr('src').replace('width=1920&height=850', 'width=1036&height=500'));
    });
  }

  if ($('body.donate').length) {
    $('.carouselSlide img').each(function () {
      $(this).attr('srcset', '');
      $(this).attr('src', $(this).attr('src').replace('width=1920&height=620', 'width=1036&height=500'));
      $(this).attr('src', $(this).attr('src').replace('width=1920&height=850', 'width=1036&height=500'));
    });
  }

  $('.associatedRelatedPosts').insertBefore('.pageFooterWrapper');

  if ($('.adminBarEdit').length) {
    $('#pageHeader').css('top', $('.adminBarEdit').css('height'));
  }

  if ($('body').hasClass('subsiteInner')) {
    $('#bodyForm > div.pageWrapper.pagePostPage > article > script').remove();
    $('#bodyForm > div.pageWrapper.pagePostPage > article > section.headerWrapper.headerWrapperSubsite').remove();
    $('#bodyForm > div.pageWrapper.pagePostPage > article > section.headerText.headerTextSubsite').remove();
    $('#bodyForm > div.pageWrapper.pagePostPage > article > div.subsiteBody').remove();
  }
})

$(window).on("load resize scroll", function (e) {
  $('.carouselControls').css('height', $('.carouselSlideTitle').css('height'));
});

//document ready
$(document).ready(function () {
  if ($('body').hasClass('subsite')) {
    // Replace search with "Back to main site" link
    $('.searchWrapper').replaceWith('<a class="searchButton" href="/">Back to main site</a>');

    if ($('body').hasClass('homepage')) {
      $('<div class="carouselDetailWrapper"><h2 class="carouselSlideHeading"></h2></div>').insertAfter('.carouselSlideTitle');
      $('.carouselSlideHeading').text($('#h1SubsiteTitle').text());
      $(window).on("load resize scroll", function (e) {
        $('.carouselDetailWrapper').css('top', $('.pageHeader').css('height'));
      })
    }
  }
});

// Timeline https://raisingit.atlassian.net/browse/DEL-216
// Timeline slider - setup
// Post category - Unclickable
$(".listedPost.PostCategory_unclickable").prop("onclick", null).css("cursor", "auto");
$(".listedPost.PostCategory_unclickable a").attr("href", null);


// Timeline - Remove the listFooter
$("body.pages-category-timeline footer.listFooter").remove();


// Resize images for Timeline pages
$('.listedPost.PostCategory_timeline img.banner.listingBanner').each(function () {
  var updatedSrc = $(this)
    .attr('src')
    .replace(/w=([0-9]*)&h=([0-9]*)/, 'w=600&h=600') // Non smart-cropped
    .replace(/width=([0-9]*)&height=([0-9]*)/, 'width=600&height=600'); // Smart cropped
  $(this).attr('src', updatedSrc);
});


// // Slick slider for Timeline
// $("body.pages-category-timeline").find('.listContent').slick({
//   slidesToShow: 5,
//   centerMode: true,
//   slidesToScroll: 1,
//   arrows: true,
//   dots: false,
//   autoplay: false,
//   infinite: false,
//   responsive: [
//     {
//       breakpoint: 1680,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 1
//       }
//     },
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1
//       }
//     }
//   ]
// });   

// On clicking .searchButton, go to /search https://raisingit.atlassian.net/browse/DEL-247
$('.searchButton').click(function (e) {
  window.location.href = '/search';
});